<template>
  <div v-if="fetchSettings.pending">Loading...</div>
  <div v-else-if="fetchSettings.error">
    {{ fetchSettings.error }}
  </div>
  <item-list-view
    v-else-if="settings"
    :api="practicesService"
    :headers="headers"
    items-key="items"
    id-key="id"
    title="Практики"
    :allowed-search="false"
    :allowed-create="true"
    :allowed-remove="true"
    :update-route="(item) => ({ name: 'PracticeItem', params: { id: item.id } })"
    :create-route="{ name: 'PracticeItem', params: { id: 'new' } }"
    remove-title="Удаление практики"
    remove-message="Вы действительно хотите удалить практику '<%= item.name %>'?"
    disable-pagination
    :draggable="isDraggable"
    @[ON_REORDER]="onReorder"

  >
    <template #pretable>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col>
                <validation-provider
                  slim
                  rules="required"
                  v-slot="{ errors }"
                  name="sorting"
                >
                  <sorting-select
                    v-model="settings.sortingType"
                    outlined
                    style="width: 140px"
                    label="Сортировка"
                    dense
                    light
                    name="sorting"
                    hide-details="auto"
                    :error-messages="errors"
                  />
                </validation-provider>
                <div class="mt-2" v-if="sorting === SORTING_TYPE_MANUAL">
                  Если сортировка не применилась, попробуйте сбросить
                  <router-link target="_blank" to="/cache">общий кэш</router-link>
                </div>
              </v-col>
              <v-col class="col-auto">
                <v-btn
                  color="success"
                  small
                  @click="handleSubmit(() => save.run())"
                  title="Save"
                  :disabled="save.pending"
                >
                  Применить
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </validation-observer>
    </template>
    <template v-slot:item.draggable>
      <v-btn icon small>
        <v-icon>mdi-drag</v-icon>
      </v-btn>
    </template>
  </item-list-view>
</template>

<script>
import { createRequest } from '@/utils'
import ItemListView, { ON_REORDER } from '@/components/ItemListView'
import { SORTING_TYPE_MANUAL, practicesService } from '@/services/practices'
import SortingSelect from '@/components/Practice/SortingSelect.vue'
import { practicesSettingsService } from '@/services/practices-settings'

export default {
  components: {
    ItemListView,
    SortingSelect
  },
  data () {
    return {
      order: undefined,
      ON_REORDER,
      sorting: SORTING_TYPE_MANUAL,
      SORTING_TYPE_MANUAL,
      practicesService,
      settings: null,
      fetchSettings: createRequest(async () => {
        this.settings = await practicesSettingsService.getAll()
      }),
      save: createRequest(() => {
        return practicesSettingsService.update(practicesSettingsService.toDto(this.settings))
      }),
    }
  },
  created () {
    return this.fetchSettings.run()
  },
  computed: {
    isDraggable () {
      return this.settings.sortingType === SORTING_TYPE_MANUAL
    },
    headers () {
      const canDrag = this.settings.sortingType === SORTING_TYPE_MANUAL
      return [
        { text: '', value: 'draggable', hidden: !canDrag },
        { text: 'Название', value: 'name' },
        { text: '', value: 'action' }
      ].filter(({ hidden }) => !hidden)
    }
  },
  methods: {
    onReorder (items) {
      this.settings.orderedPracticeIds = items.map(({ id }) => id)
    }
  }
}
</script>
