<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-text="text"
    item-value="value"
  />
</template>

<script>
import { SORTING_TYPE_MANUAL, SORTING_TYPE_RANDOM } from '@/services/practices-settings'

export default {
  data () {
    return {
      items: [
        {
          value: SORTING_TYPE_MANUAL,
          text: 'Вручную'
        },
        {
          value: SORTING_TYPE_RANDOM,
          text: 'Случайно'
        }
      ]
    }
  }
}
</script>
